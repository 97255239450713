import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-space',
  templateUrl: './edit-space.component.html',
  styleUrls: ['./edit-space.component.css']
})
export class EditSpaceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
